import './styles/orderPageStyle';
import React from 'react';
import { OrderPageContextProvider } from 'page/order/context/orderPageContext';
import OrderPageContent from './orderPageContent';
import { useAsync } from 'react-async-hook';
import OrderPageMainLayout from './orderPageMainLayout';
import { OrderAPI } from '../../appAPI';
import { isServer } from '../../utils/gatsbyUtils';
import OrderLayoutSpinner from './components/orderLayoutSpinner';
import { AdminModeProvider } from './utils/orderPageAdminUtils';
import { usePageParams } from '../../layout/appContext';
import { useOrderRestorationInitialState } from './utils/orderPageRestoreUtils';
import OrderLayoutError from './orderPageRestore';

// Wrapping here on purpose
// MainLayout has to render first otherwise useHeaderSize returns default value!
const OrderPageAdminRestore = () => {
  if (isServer()) {
    return <OrderLayoutSpinner />;
  }
  // const adminModeAllowedAsync = useAsync(OrderAPI.adminModeAllowed, []);
  //
  // if (!adminModeAllowedAsync.result) {
  //   // TODO handle load errors
  //   return <OrderLayoutSpinner />;
  // }
  //
  // if (!adminModeAllowedAsync.result.allowed) {
  //   // TODO dara
  //   return (
  //     <OrderPageMainLayout>
  //       <div>You are not an admin</div>
  //     </OrderPageMainLayout>
  //   );
  // }

  const { shootingId } = usePageParams();
  if (!shootingId) {
    return <OrderLayoutSpinner />;
  }
  const asyncRestoredShooting = useAsync(OrderAPI.restoreShootingAdmin, [
    shootingId,
  ]);

  const { initialState, initialStep } = useOrderRestorationInitialState(
    shootingId,
    asyncRestoredShooting.result,
  );

  if (!asyncRestoredShooting.result) {
    if (asyncRestoredShooting.error) {
      return <OrderLayoutError />;
    }
    return <OrderLayoutSpinner />;
  }

  console.debug(
    'Restore shooting',
    shootingId,
    asyncRestoredShooting,
    initialState,
    initialStep,
  );

  return (
    <AdminModeProvider>
      <OrderPageMainLayout>
        <OrderPageContextProvider
          initialState={initialState}
          initialStep={initialStep}
        >
          <OrderPageContent />
        </OrderPageContextProvider>
      </OrderPageMainLayout>
    </AdminModeProvider>
  );
};

export default OrderPageAdminRestore;
